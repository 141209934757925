/* Base button styles */
.btn {
    @apply flex items-center justify-center gap-2 rounded-md font-medium shadow;
}

/* Button variants */
.btn-primary {
    @apply bg-primary text-primary-foreground;
}

.btn-secondary {
    @apply bg-secondary text-secondary-foreground;
}

.btn-danger {
    @apply bg-danger text-danger-foreground;
}

.btn-warning {
    @apply bg-warning text-warning-foreground;
}

.btn-success {
    @apply bg-success text-success-foreground;
}

.btn-info {
    @apply bg-info text-info-foreground;
}

.btn-accent {
    @apply bg-accent text-accent-foreground;
}

.btn-light {
    @apply bg-light text-light-foreground;
}

.btn-excel {
    @apply bg-excel text-primary-foreground;
}

.btn-pdf {
    @apply bg-pdf text-primary-foreground;
}

.btn-csv {
    @apply bg-csv text-primary-foreground;
}

.btn-link {
    @apply shadow-none;
}

/* Button sizes */
.btn-sm {
    @apply p-1;
}

.btn-md {
    @apply p-2;
}

.btn-lg {
    @apply p-2 text-lg;
}

/* Button states */
.btn:disabled {
    @apply cursor-not-allowed bg-neutral-700 opacity-30 grayscale dark:bg-neutral-500;
}

.btn:not(:disabled):hover {
    @apply bg-primary/20;
}

/* Icon position */
.btn-icon-end {
    @apply flex-row-reverse;
}
