@tailwind base;
@tailwind components;
@tailwind utilities;

/* Color system variables */
@layer base {
    :root {
        --background: 243 244 246;
        --background-lighter: 249 250 251;
        --background-darker: 229 231 235;

        --foreground: 31 41 55;

        --card: 255 255 255;
        --card-foreground: 28 25 23;

        --popover: 250 250 249;
        --popover-foreground: 28 25 23;

        --primary: 2 132 199;
        --primary-foreground: 250 250 249;

        --secondary: 71 85 105;
        --secondary-foreground: 250 250 249;
        --secondary-lighter: 3 105 161;

        --tertiary: 151 198 223;

        --quaternary: 204 230 244;

        --muted: 87 83 78;
        --muted-foreground: 250 250 249;

        --accent: 234 88 12;
        --accent-foreground: 250 250 249;

        --danger: 220 38 38;
        --danger-foreground: 250 250 249;

        --warning: 202 138 4;
        --warning-foreground: 250 250 249;

        --success: 22 163 74;
        --success-foreground: 250 250 249;

        --info: 8 145 178;
        --info-foreground: 28 25 23;

        --link: 30 58 138;
        --link-foreground: 250 250 249;

        --light: 250 250 249;
        --light-foreground: 28 25 23;

        --input: 255 255 255;
        --input-foreground: 28 25 23;

        --excel: 19 120 57;
        --pdf: 166 25 25;
        --csv: 145 89 5;

        --radius: 0.5rem;
        --sidebar-min-width: 16rem;

        --ngx-json-string: #000;
        --ngx-json-number: #000;
        --ngx-json-boolean: #000;
        --ngx-json-null: #000;
        --ngx-json-null-bg: #fff;
        --ngx-json-key: #a8a29e;
        --ngx-json-font-family: 'Roboto';
    }

    .dark {
        --background: 41 37 36;
        --foreground: 245 245 244;

        --background-lighter: 41 37 36;
        --background-darker: 48 45 43;

        --card: 64 64 64;
        --card-foreground: 241 245 249;

        --popover: 68 64 60;
        --popover-foreground: 241 245 249;

        --secondary: 41 37 36;
        --secondary-foreground: 250 250 249;

        --tertiary: 79 80 81;

        --quaternary: 89 90 91;

        --muted: 168 162 158;
        --muted-foreground: 250 250 249;

        --accent: 249 115 22;

        --danger: 185 28 28;

        --warning: 161 98 7;

        --success: 21 128 61;

        --info: 8 145 178;

        --link: 191 219 254;

        --light: 68 64 60;
        --light-foreground: 241 245 249;

        --input: 100 100 100;
        --input-foreground: 241 245 249;

        --excel: 0 80 30;
        --pdf: 130 20 20;
        --csv: 115 70 3;

        --ngx-json-string: #fff;
        --ngx-json-number: #fff;
        --ngx-json-boolean: #fff;
        --ngx-json-null: #fff;
        --ngx-json-null-bg: #404040;
    }
}

a {
    @apply text-link;
}

* {
    font-family: 'Roboto', sans-serif;
}

/*
 * Scrollbar
 */

::-webkit-scrollbar {
    @apply h-2 w-2;
}

::-webkit-scrollbar-thumb {
    @apply cursor-pointer bg-primary;
}

::-webkit-scrollbar-thumb:window-inactive {
    @apply bg-muted;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
